import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import ProducerTableItem from "@/entity/aftersale/inspection/ProducerTableItem";
import API from "@/api/aftersale/inspection/producer";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";

@Options({
    name: "app-aftersale-inspection-producer",
    components: {
        "app-draggable-listview": DraggableListview,
    },
})
export default class Producer extends BaseTableMixins<ProducerTableItem> implements Partial<BaseTableInterface<ProducerTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.editTableItem = new ProducerTableItem();
        this.editModalItem = new ProducerTableItem();
    }

    public activated(): void {
        this.getList(1);
    }
    //后端API
    public getApi(): any {
        return API;
    }
    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new ProducerTableItem();
        this.editModalItem = new ProducerTableItem();
        this.editModalItem.producerStatus = 1;
        this.editModalTitle = "添加设备生产厂商";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.producerName)) return "设备生产厂商不能为空！";
        return null;
    }
    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改设备生产厂商";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.producerName)) return "设备生产厂商不能为空！";
        return this.addSubmitChecking();
    }
    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "设备厂商状态",
            message: "设备生产厂商: <br>" + this.editModalItem.producerName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.producerStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.producerStatus = this.editModalItem.producerStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.producerName)) return "设备生产厂商不能为空！";
        return null;
    }
    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除设备生产厂商",
            message: "设备生产厂商: <br>" + this.editModalItem.producerName + "<br>请确认删除？",
        };
    }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.producerId)) return "类型ID不能为空！";
        return null;
    }
}
