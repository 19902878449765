export default class ProducerTableItem {
    producerId = 0;
    producerStatus = 0;
    producerName = "";
    producerRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
